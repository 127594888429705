export default function (message, key = "inheritech_key") {
  var cryptoJs = require("crypto-js");
  const keyHex = cryptoJs.enc.Utf8.parse(key);
  const decrypted = cryptoJs.DES.decrypt(
    message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
    keyHex,
    {
      mode: cryptoJs.mode.ECB,
      padding: cryptoJs.pad.Pkcs7,
    }
  );
  return decrypted.toString(cryptoJs.enc.Utf8);
}