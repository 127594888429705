<template>
  <!-- 汽车票售票订单 -->
  <div ref="carTicketOrder" class="carTicketOrder">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="85px"
      >
        <el-form-item label="订单编号：">
          <el-input
            v-model.trim="form.id"
            placeholder="请输入订单编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="起点站：">
          <el-select
            v-model="form.startSiteNameList[0]"
            filterable
            clearable
            placeholder="请选择起点站"
          >
            <el-option
              v-for="role in siteList"
              :key="role.siteID"
              :label="role.siteName"
              :value="role.siteName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="终点站：">
          <el-select
            v-model="form.endSiteNameList[0]"
            filterable
            clearable
            placeholder="请选择终点站"
          >
            <el-option
              v-for="role in siteList"
              :key="role.siteID"
              :label="role.siteName"
              :value="role.siteName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select
            v-model="form.orderStatusList"
            multiple
            clearable
            placeholder="请选择订单状态"
          >
            <el-option
              v-for="role in orderStatusList"
              :key="role.value"
              :label="role.label"
              :value="role.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="取票手机：">
          <el-input
            v-model="form.orderPhone"
            clearable
            placeholder="请输入取票手机"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单时间起：">
          <el-date-picker
            v-model="form.placeOrderDateStart"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="下单时间止：">
          <el-date-picker
            v-model="form.placeOrderDateEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间起：">
          <el-date-picker
            v-model="form.paymentStartDate"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间止：">
          <el-date-picker
            v-model="form.paymentEndDate"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="票源：">
          <el-select
            v-model="form.companyCodes"
            collapse-tags
            multiple
            clearable
            placeholder="请选择订单状态"
          >
            <el-option
              v-for="role in companyCodeList"
              :key="role.desc"
              :label="role.showDesc"
              :value="role.desc"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发车时间起：">
          <el-date-picker
            v-model="form.setOutDateStart"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发车时间止：">
          <el-date-picker
            v-model="form.setOutDateEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
            default-time="23:59:59"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button
            v-if="listFind('导出')"
            type="primary"
            size="small"
            @click="exportFun()"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :table-height="tableHeight"
      :operation="true"
      operation-width="60"
    >
      <template slot-scope="scope">
        <el-button
          v-if="listFind('查看')"
          type="text"
          size="small"
          @click="openPopupFun(scope.scopeRow)"
          >查看</el-button
        >
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 表格详情弹框 -->
    <div v-if="tablePopupShow" class="table-Popup">
      <div class="content">
        <div class="header">
          <div class="text">查看</div>
          <div class="close" @click="closeFun()"></div>
        </div>
        <div class="row">
          <div class="individual">
            <div class="label">订单编号</div>
            <div class="row-val">{{ dataPopup.id }}</div>
          </div>
          <div class="individual">
            <div class="label">起点站</div>
            <div class="row-val">{{ dataPopup.startSiteName }}</div>
          </div>
          <div class="individual">
            <div class="label">终点站</div>
            <div class="row-val">{{ dataPopup.endSiteName }}</div>
          </div>
        </div>
        <div class="row">
          <div class="individual">
            <div class="label">发车时间</div>
            <div class="row-val">{{ dataPopup.setOutTime }}</div>
          </div>
          <div class="individual">
            <div class="label">服务费</div>
            <div class="row-val">{{ dataPopup.servicePricesPer }}</div>
          </div>
          <div class="individual">
            <div class="label">支付金额</div>
            <div class="row-val">{{ dataPopup.totalPrices }}</div>
          </div>
        </div>
        <div class="row">
          <div class="individual">
            <div class="label">退票金额</div>
            <div class="row-val">{{ dataPopup.refundTicketPrices }}</div>
          </div>
          <div class="individual">
            <div class="label">退票手续费</div>
            <div class="row-val">{{ dataPopup.refundTicketServicePrices }}</div>
          </div>
          <div class="individual">
            <div class="label">订单状态</div>
            <div class="row-val">{{ dataPopup.orderStatus }}</div>
          </div>
        </div>
        <div class="row">
          <div class="individual">
            <div class="label">票源</div>
            <div class="row-val">{{ dataPopup.companyCode }}</div>
          </div>
          <div class="individual">
            <div class="label">取票验证码</div>
            <div class="row-val">{{ dataPopup.ticketCode }}</div>
          </div>
          <div v-if="dataPopup.passCode" class="individual">
            <div class="label">取票密码</div>
            <div class="row-val">{{ dataPopup.passCode }}</div>
          </div>
        </div>
        <div class="title">订单明细</div>
        <Table
          :table-data="tableData2"
          :title-name="titleName2"
          :col-class-type="true"
        ></Table>
        <div class="footer">
          <div>
            <el-button @click="closeFun">取消</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  automobileIntercityAPI,
  siteListAPI,
  orderDetailAPI,
  orderListExportAPI,
  companyCodeAPI,
} from "@/api/lib/api.js";
import decryptDes from "@/tools/decryptDes.js";
export default {
  data() {
    return {
      companyCodeList: [],
      tablePopupShow: false,
      total: 0,
      dataPopup: {},
      form: {
        pageSize: 10,
        currentPage: 1,
        id: "", //订单编号
        orderPhone: "", //取票手机
        placeOrderDateStart: "", //下单日期起
        placeOrderDateEnd: "", //下单日期止
        setOutDateStart: "", //发车日期起
        setOutDateEnd: "", //发车日期止
        orderStatusList: [], //订单状态集合
        startSiteNameList: [""], //起点站集合
        endSiteNameList: [""], //终点站集合
        companyCodes: [], //票源集合
        paymentEndDate: null,
        paymentStartDate: null,
      },
      tableData2: [],
      titleName2: [
        {
          title: "票号",
          props: "id",
          width: 230,
        },
        {
          title: "座位类型",
          props: "seatTypeName",
        },
        {
          title: "座位号",
          props: "seatNumber",
        },
        {
          title: "票种",
          props: "ticketType",
          SpecialJudgment: (res) => {
            return res == 0 ? "儿童" : res == 1 ? "成人" : "";
          },
        },
        {
          title: "乘客姓名",
          props: "passengerName",
        },
        {
          title: "手机号码",
          props: "passengerPhone",
        },
        {
          title: "身份证号码",
          props: "idCardNo",
          width: 120,
          SpecialJudgment: (res) => {
            return decryptDes(res).replace(
              /^(.{4})(?:\d+)(.{3})$/,
              "$1******$2"
            );
          },
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "支付金额",
          props: "totalPrices",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return (
              this.orderStatusList.find((val) => val.value == res)?.label ||
              "已退票"
            );
          },
        },
        {
          title: "退票金额",
          props: "refundTicketPrices",
        },
        {
          title: "退票手续费",
          props: "refundTicketServicePrices",
          width: 120,
        },
        {
          title: "退票时间",
          props: "updateTime",
          width: "170",
        },
      ],
      tableData: [],
      titleName: [
        {
          title: "订单编号",
          props: "id",
        },
        {
          title: "业务类型",
          props: "businessType",
          SpecialJudgment: (res) => {
            return res == 2 ? "汽车票" : "";
          },
        },
        {
          title: "下单人",
          props: "passengerPhone",
        },
        {
          title: "下单时间",
          props: "createTime",
          width: "170",
        },
        {
          title: "支付时间",
          props: "wxTransactionTime",
          width: "170",
        },
        {
          title: "起点站",
          props: "startSiteName",
        },
        {
          title: "终点站",
          props: "endSiteName",
        },
        {
          title: "发车时间",
          props: "setOutTime",
          width: 170,
        },
        {
          title: "取票手机",
          props: "orderPhone",
        },
        {
          title: "订票数",
          props: "orderCount",
        },
        {
          title: "成人票数",
          props: "adultCount",
        },
        {
          title: "儿童票数",
          props: "childCount",
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "支付金额",
          props: "totalPrices",
        },
        {
          title: "票源",
          props: "companyCode",
          SpecialJudgment: (res) => {
            return res == "guangYun"
              ? "广运集团"
              : res == "beiShiTe"
              ? "倍施特"
              : "";
          },
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            let text = "";
            switch (res) {
              case 10:
                text = "待支付";
                break;
              case 20:
                text = "已支付";
                break;
              case 30:
                text = "订单取消";
                break;
              case 40:
                text = "部分退票";
                break;
              case 50:
                text = "全部退票";
                break;
              case 60:
                text = "已退票";
                break;
            }
            return text;
          },
        },
        {
          title: "退票金额",
          props: "refundTicketPrices",
        },
        {
          title: "退票手续费",
          props: "refundTicketServicePrices",
        },
        {
          title: "最后退票时间",
          props: "updateTime",
          width: "170",
        },
      ],
      siteList: [],
      orderStatusList: [
        {
          value: 10,
          label: "待支付",
        },
        {
          value: 20,
          label: "已支付",
        },
        {
          value: 30,
          label: "订单取消",
        },
        {
          value: 40,
          label: "部分退票",
        },
        {
          value: 50,
          label: "全部退票",
        },
      ],
      tableHeight: 0,
    };
  },
  methods: {
    // 导出
    exportFun() {
      orderListExportAPI(this.form).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName =
          "汽车售票订单表 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 点击查看按钮
    openPopupFun(item) {
      this.dataPopup = {
        id: item.id,
        startSiteName: item.startSiteName,
        endSiteName: item.endSiteName,
        setOutTime: item.setOutTime,
        servicePricesPer: item.servicePricesPer,
        totalPrices: item.totalPrices,
        refundTicketPrices: item.refundTicketPrices,
        refundTicketServicePrices: item.refundTicketServicePrices,
        orderStatus: this.statusFun(item.orderStatus),
        companyCode:
          item.companyCode == "guangYun"
            ? "广运集团"
            : item.companyCode == "beiShiTe"
            ? "倍施特"
            : "",
      };
      orderDetailAPI({ id: item.id }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData2 = res.data.childOrderList;
          this.dataPopup.passCode = res.data.passCode;
          this.dataPopup.ticketCode = res.data.ticketCode;
          this.tablePopupShow = true;
        }
      });
    },
    //   状态解析返回
    statusFun(val) {
      switch (val) {
        // 待支付
        case 10:
          return "待支付";
        // 已支付
        case 20:
          return "已支付";
        // 订单取消
        case 30:
          return "订单取消";
        // 部分退票
        case 40:
          return "部分退票";
        // 全部退票
        case 50:
          return "全部退票";
        // 已退票
        case 60:
          return "已退票";
      }
    },
    // 关闭弹窗
    closeFun() {
      this.tablePopupShow = false;
    },
    // 点击查询按钮
    queryFun() {
      this.form.currentPage = 1;
      this.renderData();
    },
    // 数据获取方法
    renderData() {
      automobileIntercityAPI(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.carTicketOrder.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        36;
    },
    onSearch() {},
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.renderData();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.renderData();
    },
    // 票源列表数据获取
    companyCodeRender() {
      companyCodeAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.companyCodeList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取上一个月时间,返回yyyy-MM-dd字符串
    getLastMonthTime(date) {
      //  1    2    3    4    5    6    7    8    9   10    11   12月
      var daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      var strYear = date.getFullYear();
      var strDay = date.getDate();
      var strMonth = date.getMonth() + 1;
      //一、解决闰年平年的二月份天数   //平年28天、闰年29天//能被4整除且不能被100整除的为闰年,或能被100整除且能被400整除
      if ((strYear % 4 === 0 && strYear % 100 !== 0) || strYear % 400 === 0) {
        daysInMonth[2] = 29;
      }
      if (strMonth - 1 === 0) {
        //二、解决跨年问题
        strYear -= 1;
        strMonth = 12;
      } else {
        strMonth -= 1;
      }
      //  strDay = daysInMonth[strMonth] >= strDay ? strDay : daysInMonth[strMonth];
      strDay = Math.min(strDay, daysInMonth[strMonth]); //三、前一个月日期不一定和今天同一号，例如3.31的前一个月日期是2.28；9.30前一个月日期是8.30

      if (strMonth < 10) {
        //给个位数的月、日补零
        strMonth = "0" + strMonth;
      }
      if (strDay < 10) {
        strDay = "0" + strDay;
      }
      var datastr = strYear + "-" + strMonth + "-" + strDay;
      return datastr;
    },
  },
  mounted() {
    this.companyCodeRender();
    // 获取起终站点数据
    siteListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.siteList = res.data;
      }
    });
    // 获取表格数据接口
    // automobileIntercityAPI().then(res => {});
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
    // 时间计算，默认一个月
    this.form.placeOrderDateStart =
      this.getLastMonthTime(new Date()) + " 00:00:00";
    let day = new Date();
    this.form.placeOrderDateEnd =
      day.getFullYear() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getDate() +
      " 23:59:59";
    this.renderData();
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style scoped lang="scss">
.table-Popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  .content {
    position: absolute;
    z-index: 10000;
    background: #ffffff;
    border-radius: 10px;
    width: 1000px;
    padding: 0 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 1px;
    .row {
      height: 34px;
      width: 100%;
      line-height: 34px;
      margin: 20px 0;
      display: flex;
      .individual {
        display: flex;
        width: 33.3%;
        height: 100%;
        .label {
          width: 86px;
          padding-right: 14px;
          text-align: right;
          display: inline-block;
        }
        .row-val {
          padding: 0 10px;
          display: inline-block;
          width: calc(100% - 120px);
          border: 1px solid #d3d3d3;
          border-radius: 4px;
          color: #333333;
        }
      }
    }
    .header {
      padding: 18px 24px;
      height: 24px;
      width: calc(100% - 48px);
      border-bottom: 1px solid #d7d7d7;
      .text {
        margin-top: 8px;
        display: inline-block;
        font-size: 14px;
      }
      .close {
        float: right;
        margin-top: 10px;
        cursor: pointer;
        width: 12px;
        height: 12px;
        background: url("../../../assets/images/home/close.png") no-repeat;
        background-size: 100%;
        transform: translateY(-50%);
      }
    }
    .footer {
      padding: 10px 0px 18px 24px;
      height: 32px;
      width: calc(100% - 24px);
      border-bottom: none !important;
      border-top: 1px solid #d7d7d7;
      .el-button {
        float: right;
        margin-left: 14px;
      }
      .el-button:first-child {
        background: #336fee;
        color: #ffffff;
      }
    }
  }
}
.Table {
  margin-top: 16px;
}
.carTicketOrder {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}
</style>
